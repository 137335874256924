import React, { useState } from 'react'
import { Col, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { useMyContext } from '../../../../../Context/MyContextProvider'
import { processImageFile } from '../../CustomComponents/AttendeeStroreUtils'

const WelcomeModal = () => {
    const { api, authToken, successAlert } = useMyContext()
    const [image, setImage] = useState('')
    const [smImage, setSmImage] = useState('')
    const [url, setUrl] = useState('')
    const [smUrl, setSmUrl] = useState('')

    const handleImageChange = async (e, setImageFunction) => {
        const file = e.target.files[0]
        if (file) {
            const processedImage = await processImageFile(file)
            setImageFunction(processedImage)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            formData.append('image', image)
            formData.append('sm_image', smImage)
            formData.append('url', url)
            formData.append('sm_url', smUrl)

            const res = await axios.post(`${api}wc-mdl-store`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (res.data.status) {
                successAlert('Success', 'Welcome Modal Settings Updated Successfully')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <h4 className="mb-3">Welcome Modal Settings</h4>
            <Col sm="6" className="mb-3">
                <Form.Label>Modal Image</Form.Label>
                <Form.Control
                    type="file"
                    onChange={(e) => handleImageChange(e, setImage)}
                />
            </Col>
            <Col sm="6" className="mb-3">
                <Form.Label>Small Modal Image</Form.Label>
                <Form.Control
                    type="file"
                    onChange={(e) => handleImageChange(e, setSmImage)}
                />
            </Col>
            <Col sm="6" className="mb-3">
                <Form.Label>URL</Form.Label>
                <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </Col>
            <Col sm="6" className="mb-3">
                <Form.Label>Small Modal URL</Form.Label>
                <Form.Control
                    type="text"
                    value={smUrl}
                    onChange={(e) => setSmUrl(e.target.value)}
                />
            </Col>
            <Col sm="12" className="d-flex justify-content-end mb-3">
                <Button onClick={handleSubmit}>Save </Button>
            </Col>
        </>
    )
}

export default WelcomeModal
